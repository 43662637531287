import { useStorage, type RemovableRef } from "@vueuse/core";

interface SortState {
  sortField: string;
  isSortAsc: boolean;
  opinionStatus: string[];
}

export const useSortStore = defineStore("sort", {
  state: (): SortState => {
    const meetingId = useMeetingId();

    return useStorage(
      `digiform:sort:${meetingId.value ?? "default"}`,
      {
        sortField: "firstname",
        isSortAsc: true,
        opinionStatus: [],
      },
      localStorage
    ).value
  },
  actions: {
    setSortField(field: string) {
      this.sortField = field;
    },
    setSortAsc(asc: boolean) {
      this.isSortAsc = asc;
    },
    setOpinionStatus(status: string[]) {
      this.opinionStatus = status;
    }
  },
  getters: {
    getState: (state) => {
      return state;
    },
  },
});
